import { ArrowDropDownOutlined } from '@mui/icons-material';
import { Unstable_Grid2 as Grid } from '@mui/material';

import { FC } from 'react';

type SelectChevronProps = {
  className?: string;
};

export const SelectChevron: FC<SelectChevronProps> = props => (
  <Grid
    alignItems='center'
    className={props.className}
    display='flex'
    height='1em'>
    <ArrowDropDownOutlined />
  </Grid>
);
