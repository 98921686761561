import { CircularProgress, styled } from '@mui/material';

import { FC } from 'react';

export type LoaderProps = {
  'data-testid'?: string;
  className?: string;
};

const LoaderStyled = styled('div')({
  alignItems: 'center',
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0
});

export const Loader: FC<LoaderProps> = props => (
  <LoaderStyled
    aria-busy
    className={props.className}
    data-component='Loader'
    data-testid={props['data-testid']}>
    <CircularProgress />
  </LoaderStyled>
);

Loader.displayName = 'Loader';
