import { useGetSessionInfo, useGetStatus } from '@sentinel/hooks';
import { useToggle } from '@vestwell-frontend/hooks';
import { Modal, WizardProvider } from '@vestwell-frontend/ui';

import { FC, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { mfaNudge, MfaNudgeState } from '../config/mfaNudge';
import { routes } from '../router/routes';

export const MfaNudgePage: FC = () => {
  const navigate = useNavigate();

  const user = useGetSessionInfo('user');

  useGetStatus({
    query: {
      refetchInterval: 10 * 1000,
      refetchOnWindowFocus: true,
      useErrorBoundary: true
    }
  });

  useEffect(() => {
    if (user.data && !user.data.hasMfaNudge) {
      navigate(routes.PERSONAS);
    }
  }, [user.data]);

  const [isOpen, toggleOpen] = useToggle(true);

  return user.data?.hasMfaNudge ? (
    <WizardProvider<MfaNudgeState>
      initialState={mfaNudge.initialState}
      name='mfaNudge'
      steps={mfaNudge.steps}
      useLocalStorage>
      <Modal
        aria-label='Two-Factor Authentication Setup'
        data-testid='mfaNudge'
        lgWidth='34vw'
        locked={isOpen}
        mdWidth='75vw'
        onRequestClose={toggleOpen}
        styles={{ overlay: 'bg-white' }}>
        <Outlet />
      </Modal>
    </WizardProvider>
  ) : null;
};

MfaNudgePage.displayName = 'MfaNudgePage';
