import isPropValid from '@emotion/is-prop-valid';
import { Skeleton, SkeletonProps, styled } from '@mui/material';

import { FC, useId } from 'react';

import { useIsAnimationActive } from '../helpers';
import { TextProps } from './Text';

export type LoadingProps = Omit<SkeletonProps, 'variant'> & {
  'aria-labelledby'?: string;
  'data-testid'?: string;
  className?: string;
  variant?: TextProps['variant'];
};

const LoadingStyled = styled(Skeleton, {
  shouldForwardProp: isPropValid
})<LoadingProps>(({ height, theme, width, ...props }) => {
  const variantStyles = {
    a1: {
      borderRadius: '0.437rem',
      width: '6rem'
    },
    a2: {
      borderRadius: '0.625rem',
      width: '7rem'
    },
    b1: {
      borderRadius: '0.437rem',
      width: '6rem'
    },
    b2: {
      borderRadius: '0.625rem',
      width: '7rem'
    },
    c1: {
      borderRadius: '0.437rem',
      width: '6rem'
    },
    c2: {
      borderRadius: '0.625rem',
      width: '12rem'
    },
    d1: {
      borderRadius: '0.437rem',
      width: '6rem'
    },
    d2: {
      borderRadius: '0.437rem',
      width: '12rem'
    },
    e1: {
      borderRadius: '0.437rem',
      width: '6rem'
    },
    e2: {
      borderRadius: '0.437rem',
      width: '12rem'
    },
    f1: {
      borderRadius: '0.437rem',
      width: '6rem'
    },
    f2: {
      borderRadius: '0.3125rem',
      width: '7rem'
    },
    g1: {
      borderRadius: '0.437rem',
      width: '6rem'
    },
    g2: {
      borderRadius: '0.437rem',
      width: '6rem'
    },
    i1: {
      borderRadius: '0.3125rem',
      width: '6rem'
    },
    i2: {
      borderRadius: '0.3125rem',
      width: '6rem'
    },
    j1: {
      borderRadius: '0.3125rem',
      width: '6rem'
    },
    j2: {
      borderRadius: '0.3125rem',
      width: '6rem'
    },
    k3: {
      borderRadius: '0.437rem',
      width: '7rem'
    }
  }[props['data-variant']] || {
    borderRadius: '0.3125rem',
    width: '100%'
  };

  return {
    borderRadius: variantStyles.borderRadius,
    display: 'inline-block',
    maxWidth: '100%',
    width: variantStyles.width
  };
});

export const Loading: FC<LoadingProps> = ({ variant, ...props }) => {
  const isAnimationActive = useIsAnimationActive();

  const labelId = useId();

  return (
    <LoadingStyled
      {...props}
      animation={isAnimationActive ? undefined : false}
      aria-busy
      aria-labelledby={props['aria-labelledby'] || labelId}
      aria-valuetext='Loading'
      data-variant={variant}
      role='progressbar'>
      <>&nbsp;</>
      <span className='sr-only' id={labelId} role='presentation'>
        Loading
      </span>
    </LoadingStyled>
  );
};

Loading.displayName = 'Loading';
