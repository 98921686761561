import { Check, Close } from '@mui/icons-material';
import { List, ListItem, ListItemIcon } from '@mui/material';
import { passwordErrorMsg } from '@sentinel/utils';
import { Text } from '@vestwell-frontend/ui';

import { useFormikContext } from 'formik';
import { pick } from 'lodash';
import { FC, useMemo } from 'react';

const ERRORS = pick(passwordErrorMsg, [
  'minLength',
  'obvious',
  'oneNumber',
  'oneSpecialCharacter',
  'repeatingChars',
  'contextSpecific'
]);

export const validatePasswords = schema => async values => {
  if (typeof schema === 'function') {
    schema = schema();
  }
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (err) {
    return err.inner.reduce(
      (errors, error) => {
        if (error.path === 'password') {
          errors[error.path] = errors.passwordConditions.push(error.message);
        }
        errors[error.path] = error.message;

        return errors;
      },
      { passwordConditions: [] }
    );
  }
};

export const PasswordCheckList: FC<{
  className?: string;
  describedById?: string;
}> = props => {
  const ctx = useFormikContext<{
    password: string;
    passwordConfirm: string;
    passwordConditions: string[];
  }>();

  const checkList = useMemo(
    () =>
      Object.keys(ERRORS)
        .sort()
        .map(error => ({
          checked: !ctx.errors.passwordConditions?.includes(ERRORS[error]),
          id: error,
          message: ERRORS[error]
        })),
    [ctx.errors.passwordConditions]
  );

  return (
    <div className={props.className} data-testid='passwordCheckList'>
      <Text color='grey100' mt={2} variant='j2'>
        Your password must:
      </Text>
      <List data-component='list' sx={{ p: 0 }}>
        {checkList.map(item => (
          <ListItem
            data-checked={item.checked}
            data-component='listItem'
            data-testid={item.id}
            key={item.id}
            sx={{ my: 2, p: 0 }}>
            <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
              {item.checked ? (
                <Check fontSize='small' style={{ color: '#19855F' }} />
              ) : (
                <Close fontSize='small' style={{ color: '#C52C48' }} />
              )}
            </ListItemIcon>
            <span className='sr-only'>
              {item.checked
                ? 'Password Requirement Met:'
                : 'Password Requirement Not Met:'}
            </span>
            <Text color='grey50' mb={0} variant='i1'>
              {item.message}
            </Text>
          </ListItem>
        ))}
      </List>
      <span className='sr-only' id={props.describedById}>
        Create a password with a minimum of 10 characters including a mix of
        letters, numbers and symbols.
      </span>
    </div>
  );
};

PasswordCheckList.displayName = 'PasswordCheckList';
