import { Box } from '@mui/material';
import type { HttpErrorType } from '@vestwell-frontend/hooks';

import { Component, FC, forwardRef, ReactNode, Suspense } from 'react';

import { Loader } from './Loader';
import { Page } from './Page';
import { StatusCodeError } from './StatusCodeError';

type ApiBoundaryState = {
  error?: Partial<HttpErrorType>;
  code?: string;
  status?: number;
};

export type ApiBoundaryProps = {
  children?: ReactNode;
  isLoading?: boolean;
  error?: Partial<HttpErrorType>;
};

export class ApiBoundary extends Component<ApiBoundaryProps, ApiBoundaryState> {
  state = {
    code: undefined,
    error: undefined,
    status: undefined
  };

  static getDerivedStateFromError(error) {
    return {
      code: error.code,
      error,
      status: error.status ?? 500
    };
  }

  render() {
    const error = this.state.error || this.props.error;

    if (error && error?.status !== 401) {
      return (
        <Page>
          <Box
            alignItems='center'
            bottom={0}
            display='flex'
            height='100%'
            justifyContent='center'
            left={0}
            position='absolute'
            right={0}
            top={0}
            width='100%'>
            <StatusCodeError code={error.code} status={error.status} />
          </Box>
        </Page>
      );
    }

    if (this.props.isLoading) {
      return (
        <Page>
          <Loader />
        </Page>
      );
    }

    return (
      <Suspense
        fallback={
          <Page>
            <Loader />
          </Page>
        }>
        {this.props.children}
      </Suspense>
    );
  }
}

export function withApiBoundary<Props>(Component): FC<Props> {
  const Wrapper: FC<Props> = props => (
    <ApiBoundary>
      <Component {...props} />
    </ApiBoundary>
  );

  Wrapper.displayName = 'ApiBoundary';

  return Wrapper;
}

export function withApiBoundaryWithRef<Ref, Props>(Component) {
  const Wrapper = forwardRef<Ref, Props>((props, ref) => (
    <ApiBoundary>
      <Component {...props} ref={ref} />
    </ApiBoundary>
  ));

  Wrapper.displayName = 'ApiBoundaryWithRef';

  return Wrapper;
}
