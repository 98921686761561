import isPropValid from '@emotion/is-prop-valid';
import { styled } from '@mui/material';

import { forwardRef, HTMLAttributes, ReactNode } from 'react';

export type ModalContainerProps = HTMLAttributes<HTMLDivElement> & {
  className?: string;
  children: ReactNode;
  lgWidth?: number | string;
  mdWidth?: number | string;
};

const StyledModalContainer = styled('div', {
  shouldForwardProp: isPropValid
})<ModalContainerProps>(props => ({
  '& > form': {
    display: 'contents'
  },
  /** WCAG 1.4.10 */
  '@media (max-height: 500px)': {
    overflowY: 'auto'
  },
  backgroundColor: 'white',
  borderRadius: '0.75rem',
  boxShadow: '0 0 #0000, 0 0 #0000, 0px 4px 48px #0006',
  display: 'flex',
  flexDirection: 'column',
  margin: props.theme.spacing(4),
  maxHeight: 'calc(100% - 1rem)',
  outline: 'none',
  position: 'relative',
  width: props.lgWidth ?? '44rem',
  [props.theme.breakpoints.down('md')]: {
    width: props.mdWidth ?? '44rem'
  },
  [props.theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));

export const ModalContainer = forwardRef<HTMLDivElement, ModalContainerProps>(
  (props, ref) => {
    return (
      <StyledModalContainer {...props} ref={ref}>
        {props.children}
      </StyledModalContainer>
    );
  }
);

ModalContainer.displayName = 'ModalContainer';
