import { getDate, getMonth, getYear, isExists } from 'date-fns';
import { get } from 'lodash';
import * as yup from 'yup';

import {
  AVOID_SIMPLE_OR_OBVIOUS_ENGLISH_WORDS,
  HAVE_AT_LEAST_EIGHT_CHARACTERS,
  HAVE_AT_LEAST_ONE_LOWERCASE_LETTER,
  HAVE_AT_LEAST_ONE_NUMBER,
  HAVE_AT_LEAST_ONE_SPECIAL_CHARACTER,
  HAVE_AT_LEAST_ONE_UPPERCASE_LETTER
} from './consts/passwordRequirements';
import {
  CANADIAN_STATES,
  US_STATES,
  US_STATES_AND_TERRITORIES
} from './consts/states';
import { formatDate } from './formatDate';
import { parseDate } from './parseDate';
import { numericStringRegex } from './parseNumber';

const EMAIL_REGEXP =
  /^(([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*)|(\.?[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const SPECIAL_CHARACTERS_REGEXP = /^[^<>%${}]*$/;
const ZIP_US_REGEXP = /^([0-9]{5})(?:[-]([0-9]{4}))?$/;
const ZIP_CA_REGEXP =
  /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVXY][ ]\d[ABCEGHJKLMNPRSTVXY]\d$/i;
const PHONE_REGEX = /^\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{4}$/;
const EIN_REGEX = /^(\d{2}-\d{7}|\d{9})$/;
const SDAT_REGEX = /^[A-Z]+\d{8}/i;
const ADDRESS_REGEX =
  /(^(?!(.*p\.?\s*o\.?\s*box.*)|(.*post\s*office\s*box.*)))/i;
const SPECIAL_CHAR = /[~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?]/;
const UPPER_CHAR = /[A-Z]/;
const LOWER_CHAR = /[a-z]/;
const NUMBER = /[0-9]/;

export function validateEmail(
  message = 'Invalid email value provided',
  excludeEmptyString = false
) {
  return yup.string().matches(EMAIL_REGEXP, { excludeEmptyString, message });
}

export function validateSafeString(errorMessage = 'Invalid value provided') {
  return yup.string().matches(SPECIAL_CHARACTERS_REGEXP, errorMessage);
}

export function validateSdat(errorMessage = 'Invalid value provided') {
  return yup.string().matches(SDAT_REGEX, errorMessage);
}

export function validateRoutingNumber(errorMessage = 'Invalid Routing Number') {
  return yup.string().test({
    test: function (value) {
      const isFormatValid =
        value?.length === 9 && !isNaN(+value) && value !== '000000000';

      if (!isFormatValid) {
        return this.createError({
          message: errorMessage,
          path: this.path
        });
      }

      const firstTwoDigits = +value.slice(0, 2);
      const isRangeValid =
        firstTwoDigits <= 12 ||
        (firstTwoDigits >= 21 && firstTwoDigits <= 32) ||
        (firstTwoDigits >= 61 && firstTwoDigits <= 72) ||
        firstTwoDigits === 80;
      const isChecksumValid =
        (3 * (+value[0] + +value[3] + +value[6]) +
          7 * (+value[1] + +value[4] + +value[7]) +
          (+value[2] + +value[5] + +value[8])) %
          10 ===
        0;

      if (!isRangeValid || !isChecksumValid) {
        return this.createError({
          message: errorMessage,
          path: this.path
        });
      }

      return true;
    }
  });
}

type DateValidatorOptions = {
  max?: string | Date;
  min?: string | Date;
  maxErrorMessage?: string;
  minErrorMessage?: string;
  required?: boolean;
  errorMessage?: string;
};

/** @deprecated - use dateValidator instead */
export function validateDate(options) {
  return value => {
    try {
      const date = new Date(value).getTime();
      const isValid = !isNaN(date);

      if (!isValid) {
        return false;
      }

      if (options?.min && date < options.min.getTime()) {
        return false;
      }

      return true;
    } catch (e) {
      return false;
    }
  };
}

export const dateValidator = (name, options: DateValidatorOptions) =>
  yup.string().test({
    test: function (value) {
      const date = parseDate(value);
      const maxDate = options?.max && parseDate(options.max);
      const minDate = options?.min && parseDate(options.min);

      const isLessThanMax = !maxDate || date <= maxDate;
      const isGreaterThanMin = !minDate || date >= minDate;
      const isValidDate =
        date instanceof Date &&
        isExists(getYear(date), getMonth(date), getDate(date));
      const isValid =
        date instanceof Date && !isNaN(date.getTime()) && isValidDate;

      if (options.required && !isValid) {
        return this.createError({
          message:
            options.errorMessage || `${name} is an invalid date [MM/DD/YYYY]`,
          path: this.path
        });
      }

      if (value && !isLessThanMax) {
        return this.createError({
          message:
            options.maxErrorMessage ||
            `${name} must be less than ${formatDate(maxDate as string)}`,
          path: this.path
        });
      }

      if (value && !isGreaterThanMin) {
        return this.createError({
          message:
            options.minErrorMessage ||
            `${name} must be greater than ${formatDate(minDate as string)}`,
          path: this.path
        });
      }

      return true;
    }
  });

export function validateZip(
  message = 'Invalid Zip',
  excludeEmptyString = false,
  country: 'US' | 'CA' = 'US'
) {
  return yup
    .string()
    .matches(country === 'US' ? ZIP_US_REGEXP : ZIP_CA_REGEXP, {
      excludeEmptyString,
      message
    });
}

export function validateConfirmation(
  field: string,
  errorMessage = 'Confirmation does not match'
) {
  return yup.string().test({
    test: function (value) {
      return (
        get(this.parent, field) === value ||
        this.createError({
          message: errorMessage,
          path: this.path
        })
      );
    }
  });
}

export function validateName(
  message = 'Invalid Name',
  excludeEmptyString = false
) {
  return yup.string().matches(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*\.?$/, {
    excludeEmptyString,
    message
  });
}

export function validateNumericString(
  message = 'Invalid Number',
  excludeEmptyString = false
) {
  return yup.string().matches(numericStringRegex, {
    excludeEmptyString,
    message
  });
}

export function validateSsn(
  message = 'Invalid SSN Format',
  excludeEmptyString = true
) {
  return yup
    .string()
    .nullable(false)
    .test(function (value = '') {
      if (excludeEmptyString && value === '') {
        return true;
      }

      const rawValue = value.replace(/[^0-9]/g, '');

      // check if the value is all the same number
      if (rawValue.length === 9 && /^([0-9])\1+$/.test(rawValue)) {
        return this.createError({
          message,
          path: this.path
        });
      }

      if (['987654321', '123456789'].includes(rawValue)) {
        return this.createError({
          message,
          path: this.path
        });
      }

      /**
       * ITIN:
       * All valid ITINs are a nine-digit number in the same format
       * as the SSN (9XX-8X-XXXX), begins with a “9” and the
       * 4th and 5th digits range from 50 to 65, 70 to 88, 90 to 92,
       * and 94 to 99.
       */
      if (rawValue.startsWith('9')) {
        return (
          /^9\d{2}(5\d|6[0-5]|7\d|8[0-8]|9[0-2,4-9])\d{4}$/.test(rawValue) ||
          this.createError({
            message: message.replace('SSN', 'ITIN'),
            path: this.path
          })
        );
      }

      // SSN
      return (
        /^(?!(000|666|9))\d{3}-?(?!(00))\d{2}-?(?!(0000))\d{4}$/.test(
          rawValue
        ) ||
        this.createError({
          message,
          path: this.path
        })
      );
    });
}

export function validatePhone(
  message = 'Invalid value provided',
  excludeEmptyString = false
) {
  return yup.string().matches(PHONE_REGEX, { excludeEmptyString, message });
}

export function validateEin(
  message = 'Invalid value provided',
  excludeEmptyString = false
) {
  return yup.string().matches(EIN_REGEX, { excludeEmptyString, message });
}

export function validatePassword(value) {
  const tests = {
    [AVOID_SIMPLE_OR_OBVIOUS_ENGLISH_WORDS]:
      value.length > 0 &&
      !['password', 'vestwell', 'test', '401'].some(
        forbiddenString =>
          value.toUpperCase().indexOf(forbiddenString.toUpperCase()) > -1
      ),
    [HAVE_AT_LEAST_EIGHT_CHARACTERS]: value.length >= 8,
    [HAVE_AT_LEAST_ONE_LOWERCASE_LETTER]: LOWER_CHAR.test(value),
    [HAVE_AT_LEAST_ONE_NUMBER]: NUMBER.test(value),
    [HAVE_AT_LEAST_ONE_SPECIAL_CHARACTER]: SPECIAL_CHAR.test(value),
    [HAVE_AT_LEAST_ONE_UPPERCASE_LETTER]: UPPER_CHAR.test(value)
  };

  return Object.values(tests).every(Boolean) ? undefined : tests;
}

export function validateAddress(
  message = "Address can't be Post Office Box",
  excludeEmptyString = false
) {
  return yup.string().matches(ADDRESS_REGEX, { excludeEmptyString, message });
}

export function validateState(country: 'US' | 'US_AND_TERRITORIES' | 'CA') {
  return yup.string().test({
    test: function (value) {
      if (country === 'US' && !US_STATES[value]) {
        return this.createError({
          message: 'Invalid US State',
          path: this.path
        });
      }

      if (
        country === 'US_AND_TERRITORIES' &&
        !US_STATES_AND_TERRITORIES[value]
      ) {
        return this.createError({
          message: 'Invalid US State',
          path: this.path
        });
      }

      if (country === 'CA' && !CANADIAN_STATES[value]) {
        return this.createError({
          message: 'Invalid Canadian State/Province',
          path: this.path
        });
      }

      return true;
    }
  });
}

export function validateBlankSpaces(
  message = 'The field cannot begin or end with blank space',
  excludeEmptyString = true
) {
  return yup
    .string()
    .matches(/^\S+(\s+\S+)*$/, { excludeEmptyString, message });
}
